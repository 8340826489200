

.myContainer {
  background: #1d1d1d;
  border-radius: 10px;
}

.subtitle,
.blockquote, .labels_form, .form-control {
  font-family: "Montserrat", sans-serif;
}

.subtitle {
  color: #ff9a00;
  font-weight: 800;
}

.blockquote {
  padding-top: 2.5%;
  color: white;
}

.profile_pic {
  width: 180px;
  float: right;
  margin-left: 5%;
  margin-right: 5%;
}

.work_date {
  font-style: italic;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 576px) {
  .myContainer {
    padding: 1.5rem !important;
    margin-top: 1.5rem !important;
    margin-left: 1%;
    margin-right: 1%;
    width: 98%;
  }

  .profile_pic {
    width: 130px;
  }

  .subtitle,
  .blockquote {
    margin-left: 3%;
    margin-right: 3%;
  }
  .subtitle {
    font-size: 24px;
  }
  .blockquote {
    font-size: 16px;
  }
  .work_date {
  margin-bottom: 4%;
  }
  .click-image {
    font-size: 13px !important;
  }
}


/* CSS Form */

.btn_form {
  font-family: "Montserrat", sans-serif;
}

/*
#inputName {
  margin-top: 3.5%;
  font-family: "Montserrat", sans-serif;
}

#inputEmail,
#inputSubject,
#inputMessage {
  margin-top: 1.5%;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 576px) {
  #inputName,
  #inputEmail,
  #inputSubject,
  #inputMessage {
    margin-top: 4%;
  }
}
*/

/* Logos Skills Page */

.skillset>div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin: 1rem;
}

.skillset>div>div {
  background: var(--white);
  border-radius: .2rem;
}

.skillset img {
  cursor: context-menu;
  max-width: 10rem;
  max-height: 5rem;
  padding: 0.5rem;
}


.skillset>div>div {
  position: relative;
  margin: auto;
}

.skillset span {
  width: 140px;
  background-color: #476dfe;
  text-align: center;
  padding: .5rem .5rem;
  border-radius: .5rem;
  position: absolute;
  z-index: 1;
  top: -60px;
  left: 50%;
  transform: translate(-70px, 10px);
  transition: transform .3s ease, opacity .3s ease;
  pointer-events: none;
  opacity: 0;
}

.skillset>div>div:hover span {
  opacity: 1;
  transform: translate(-70px, 0px);
}

.skillset span:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #476dfe transparent transparent transparent;
}

.skillset .tooltip {
  color: white
}

.click-image {
  font-size: 16px;
}

@media (max-width: 991px) {
  .skillset>div {
      grid-template-columns: 1fr 1fr 1fr;
}
.marg_top8 {
  margin-top: 8%;
}
}