@media (max-width: 320px) {
  .copyright {
    font-size: 12px;
  }
}

.content-wrap-menu {
  padding-bottom: 10rem;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #000000;
}

.fab,
.fas {
  color: white;
}

.fab:hover,
.fas:hover {
  color: #feb62b;
}
