.home-title, p {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  margin-bottom: 0px;
}

.title-name {
  font-size: 40px;
}

.home_paragraph {
  margin-top: 2.5%;
  font-size: 17px;
  color: white;
  
}

.fdev, .hello {
  font-size: 26px;
}

.think_underline {
text-decoration: underline;
}

.my-name {
  color: black;
}

.title-think {
  font-weight: 800;
  line-height: 1.3;
  text-align: left;
}

.btn-dark {
  margin-top: 5%;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  padding: 1% 2%;
  background-color: #1E1E1E;
}

@media (max-width: 768px) {
  .title-name {
    font-size: 1.20em;
  }
  .name {
    font-size: 1.66em;
  }
  .copyright {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .title-think {
    font-size: 1.1em;
    text-align: center;
  }
    .home_paragraph {
      font-size: 1em;
    }
    .img-h {
      width: 90%;
    }
    .btn-home-mob {
      font-size: 1rem;
      padding-top: 3%;
      padding-bottom:3%;
      padding-left: 12%;
      padding-right: 12%;
      margin-bottom: 10%;
      width: -webkit-fill-available !important;
    }
    .desktop {
      display: none;
    }
}

@media (min-width: 769px) {
  .home-title, .home_paragraph {
    margin-left: 15%;
    margin-right: 0%;
  }
  .title-think {
    margin-right: 15%;
    margin-left: 0%;
  }
  .mobile {
    display: none;
  }
}