@media (max-width: 768px) {
  .navbar-brand {
    margin-right: 0px;
    padding-right: 0px;
  }
}

/*
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
} 
*/

/* .btn-eng,
.btn-spa {
  background-color: black;
} */

.nav-item {
  text-align: right;
  font-family: "Montserrat", sans-serif;
}

/* @media (min-width: 992px) {
  .navbar-toggle {
    display: none;
  }
} */

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.7) !important;
}





ul u {
  text-decoration: none;
}




.menu_ppl a {
    display: inline-block;
    position: relative;
}


.menu_ppl a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.menu_ppl a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}



.menu_ppl a:focus {
box-shadow: inset 0 -2px 0 white;
}



.focus {
  box-shadow: inset 0 -2px 0 white;
 }
 







/*
u {
  text-decoration: none;
}

@media (min-width: 991px) {
li {
  display: inline-block;
  position: relative;
  color: white;
}

li:first-child a {
border-bottom: solid white 2px;
}

li:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

li:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
}
*/

