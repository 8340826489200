body {
  /* background: linear-gradient(90deg, #fe7063 50%, #476dfe 50%); */
  /* background: linear-gradient(110deg, #feb62b 50%, #476dfe 50%); */
  background: linear-gradient(110deg, #ff9900 50%, #476dfe 50%);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body,
html {
  height: 100%;
}


@media (max-width: 768px) {
  body {
    /* background: #476dfe; */
    background: linear-gradient(90deg, #ff9900 72%, #476dfe 28%); 
  }
}
